<template>
  <div class="set-main">
    <!-- <GlobalHeader class="header"/> -->
    <div class="set-contant">
      <div class="set-info">
        <div class="avatar">
          <img :src="currentUserProfile.avatar ? currentUserProfile.avatar : avatarIM" alt="" />
        </div>
        <div style="paddingTop:10px">
          <div>
            <span class="basic-name">{{ userinfoserver.fullName }}</span>
               <!-- <a-popover overlayClassName="classPopover" placement="bottom">
              <template slot="content">
                <p class="feedback" @click="resign">离职申请</p>
              </template>
              <img class="classPopoverImg" src="@/assets/icons/home_icon_more.svg" alt="" />
            </a-popover> -->
            <!-- <img
              v-if="teacherBasicData.gender == 'M'"
              src="@/assets/icons/service_icon_boy.svg"
              alt=""
              class="gender"
            />
            <img v-else src="@/assets/icons/service_icon_girl.svg" alt="" class="gender" /> -->
          </div>
          <!-- <p class="teacher-address">
            <span v-if="roles">主讲教师</span>
            <span v-if="teacherBasicData.workType === 'F'">全职</span>
            <span v-else>兼职</span>
            <span>{{ teacherBasicData.data.city ? teacherBasicData.data.city : '暂无城市' }}</span>
          </p> -->
          <p class="set-acc">账号：{{ userinfoserver.email }}</p>
        </div>
      </div>
      <div class="set-avator">
        <p class="set-title">设置</p>
        <InfoTitle title="个人资料" />
        <div class="set-data">
          <div class="userAvatar" :class="{ avatarBox: imgloading && boxloading }">
            <img v-if="imgloading" :src="avatarIMSrc" alt="" />
            <div v-else>
              <a-icon type="loading" style="fontSize:30px" />
            </div>
          </div>
          <div class="uploadbtn">
            <p class="avator-text">头像</p>
            <p class="uploadTips"><span> * </span>{{ $t('user.material.tips') }}</p>
            <a-upload
              name="file"
              :file-list="fileList"
              accept="image/jpg,image/jpeg,image/png"
              :showUploadList="false"
              @change="handleChange"
            >
              <div class="ant-upload-text">{{ $t('user.material.uploadBtn') }}</div>
            </a-upload>
          </div>
        </div>
      </div>

      <div class="set-safety">
        <InfoTitle title="账号安全" />
        <div>
          <p class="email"><span>邮箱</span> {{ email }}</p>
          <p class="password"><span>密码</span> * * * * * * * * <a-icon type="edit" @click="changePassword" /></p>
        </div>
      </div>
      <div class="set-safety">
        <InfoTitle title="更多信息" />
        <div>
          <p class="email"><span>科目</span> {{initOptionsType(subject,subjectArr) }}<a-icon type="edit" @click="changeSubjectFun" /></p>
        </div>
      </div>
    <!--更换科目-->
        <a-modal
        title="修改科目"
        :visible="visibleSubject"
        @ok="saveSubject"
        @cancel="cancelSubject"
        okText="确定"
        cancelText="取消"
      >
        <a-form :form="formSubject" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="科目">
              <a-select
                allowClear
                v-decorator="['subject']"
              >
                <a-select-option :value="i.value" v-for="i of subjectArr" :key="i.value">
                  {{ i.label }}
                </a-select-option>
              </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
      <!-- 更换邮箱 -->
      <a-modal
        :visible="changeEmailVisible"
        @ok="handlechangeEmail"
        @cancel="handleCancel"
        :footer="null"
        dialogClass="set-Modal"
        :maskClosable="false"
      >
        <p class="modal-title">更换邮箱</p>
        <a-form ref="formLogin" class="user-layout-set" :form="emailForm" @submit="handleSubmit">
          <a-form-item>
            <a-input-password
              size="large"
              :placeholder="$t('user.login.passwordPlaceholder')"
              v-decorator="[
                'password',
                {
                  rules: [{ required: true, message: '请输入密码' }, { validator: this.handlepassword }],
                  validateTrigger: 'blur'
                }
              ]"
            >
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-input
              size="large"
              type="text"
              :placeholder="$t('user.login.emailPlaceholder')"
              v-decorator="[
                'newEmail',
                {
                  rules: [
                    { required: true, message: '请输入邮箱地址' },
                    { type: 'email', message: '请输入正确邮箱地址' }
                  ],
                  validateTrigger: 'change'
                }
              ]"
            >
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              size="large"
              type="text"
              :maxLength="4"
              :placeholder="$t('user.register.emailCode')"
              v-decorator="[
                'verificationCode',
                { rules: [{ required: true, message: '请输入有效验证码' }], validateTrigger: ['change'] }
              ]"
            >
            </a-input>
          </a-form-item>
          <span
            class="getCaptcha"
            size="large"
            @click.stop.prevent="getCaptcha"
            v-text="(!state.smsSendBtn && $t('user.register.getEmailCode')) || state.time + ' s'"
          ></span>
          <a-form-item style="margin-top:24px">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="set-button"
              :loading="loginBtn"
              @click.stop.prevent="handlechangeEmail"
              :disabled="
                !emailForm.getFieldsValue().newEmail ||
                  !emailForm.getFieldsValue().password ||
                  !emailForm.getFieldsValue().verificationCode ||
                  loginBtn
              "
              >{{ $t('user.login.success') }}</a-button
            >
          </a-form-item>
        </a-form>
      </a-modal>
      <!-- 更换密码 -->
      <a-modal
        :visible="changePasswordVisible"
        @ok="handlechangePassword"
        @cancel="handleCancel"
        :footer="null"
        :maskClosable="false"
        dialogClass="set-Modal"
      >
        <p class="modal-title">更换密码</p>
        <a-form ref="passwordForm" class="user-layout-set" :form="passwordForm">
          <a-form-item>
            <a-input-password
              size="large"
              placeholder="原密码"
              v-decorator="[
                'originalPassword',
                { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' }
              ]"
            >
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-input-password
              size="large"
              :placeholder="$t('user.login.passwordPlaceholder')"
              v-decorator="[
                'newPassword',
                {
                  rules: [{ required: true, message: '请输入密码' }, { validator: this.handlePasswordDetect }],
                  validateTrigger: ['change', 'blur']
                }
              ]"
            >
            </a-input-password>
          </a-form-item>
          <a-form-item style="margin-top:24px">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="set-button"
              :loading="loginBtn"
              @click.stop.prevent="handlechangePassword"
              :disabled="
                !passwordForm.getFieldsValue().originalPassword ||
                  !passwordForm.getFieldsValue().newPassword ||
                  loginBtn
              "
              >{{ $t('user.login.success') }}</a-button
            >
          </a-form-item>
        </a-form>
      </a-modal>
      <dialog-cropper
        :cropperShow.sync="cropperShow"
        :info="imgInfo"
        :cropperImg="cropperImg"
        @upload="handleSubmit"
      ></dialog-cropper>
      <component :is="currentView" @cancal="cancal"></component>
    </div>
  </div>
</template>
<script>
import InfoTitle from '@/components/WkInfoTitle/InfoTitle'
import GlobalHeader from '@/components/GlobalHeader'
import dialogCropper from '@/components/WKcropper/dialogCropper'
import { getSmsCaptcha, imgUpdate, resetEmail, setResetPassword } from '@/api/register'
import storage from 'store'
import sessionStorage from 'store/storages/sessionStorage'
import { ROLES, REMEMBERME, ACCESS_TOKEN } from '@/store/mutation-types'
import defaultImg from '@/assets/teacher.png'
import { mapActions, mapState } from 'vuex'
const roles = storage.get(ROLES)
const session = storage.createStore(sessionStorage)
const _token = storage.get(ACCESS_TOKEN)
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
import pic_teacher from '@/assets/teacher.png'
import ResignRelated from '@/views/mainHome/ResignRelated';

export default {
  components: { GlobalHeader, InfoTitle, dialogCropper,ResignRelated},
  data() {
    return {
    visibleSubject:false,
         subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
       { label: '英文', value: 'ENGLISH' },
      ],
      subject:'',
      currentView:null,
      avatar: '',
      avatarIM: '',
      teacherBasicData: null,
      roles: roles.includes('TEACHER'),
      cropperImg: '',
      imgInfo: null,
      defaultImg: defaultImg,
      fileList: [],
      updateImgData: {},
      imgloading: true,
      boxloading: false,
      changeEmailVisible: false, // 更换邮箱弹窗
      changePasswordVisible: false, // 更换密码
      emailForm: this.$form.createForm(this),
      formSubject: this.$form.createForm(this),
      passwordForm: this.$form.createForm(this),
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        percent: 10
      },
      loginBtn: false,
      interval: null,
      cropperShow: false
    }
  },
  created() {
    this.initTeacherInfo()
  },
  computed: {
    ...mapState({
      userinfoserver: state => state.user.userinfoserver,
      currentUserProfile: state => state.userIM.currentUserProfile
    }),
    avatarIMSrc() {
      return this.currentUserProfile.avatar ? this.currentUserProfile.avatar : this.avatarIM
    }
  },
  methods: {
      initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
  changeSubjectFun(){
    this.visibleSubject=true
      this.$nextTick(() => {
          
            this.formSubject.setFieldsValue({subject:this.subject});
          
          });
  },
  saveSubject(){
 this.formSubject.validateFields((err, values) => {
        if (!err) {
       let updateImgData={};
      let obj = storage.get('userInfo')
      updateImgData.userId = obj.userID
      updateImgData.roleName = obj.roleName
      updateImgData.subject=values.subject
      imgUpdate(updateImgData)
        .then(res => {
          console.log(res)
          if (res.status === 200) {
           this.cancelSubject()
            this.subject=values.subject
            let obj=storage.get('userInfo')
            obj.subject=this.subject
            storage.set('userInfo',obj)
          }
        })
        .catch(err => {
          console.log(err)
        })
        }
      });
  },
  cancelSubject(){
    this.visibleSubject=false
  },
     cancal() {
      this.currentView = null;
    },
      resign() {
      this.currentView = 'ResignRelated';
    },
    onReadyStateUpdate({ name }) {
      const isSDKReady = name === this.TIM.EVENT.SDK_READY ? true : false
      this.$store.commit('toggleIsSDKReady', isSDKReady)

      if (isSDKReady) {
        this.tim
          .getMyProfile()
          .then(({ data }) => {
            this.$store.commit('updateCurrentUserProfile', data)
          })
          .catch(error => {
            this.$store.commit('showMessage', {
              type: 'error',
              message: error.message
            })
          })
        this.$store.dispatch('getBlacklist')
      }
    },
    ...mapActions(['Login']),
    // 初始化信息
    initTeacherInfo() {
      let obj = storage.get('userinfoserver')
      this.email = obj.email
     this.subject=storage.get('userInfo').subject
      this.fullName = obj.fullName
    },
    async handleChange(info) {
      if (info.file.type !== 'image/png' && info.file.type !== 'image/jpg' && info.file.type !== 'image/jpeg') {
        this.$store.commit('showMessage', {
          type: 'error',
          message: '请上传jpg,jpeg,png图片格式'
        })
        return false
      }
      this.imgInfo = info
      getBase64(info.file.originFileObj, imgurl => {
        this.cropperShow = true
        this.cropperImg = imgurl
      })
    },
    handleSubmit(updateImgData) {
      this.previewVisible = true
      this.imgloading = true
      this.boxloading = true

      //头像上传到接口
      let obj = storage.get('userInfo')
      updateImgData.userId = obj.userID
      updateImgData.roleName = obj.roleName
      imgUpdate(updateImgData)
        .then(res => {
          console.log(res)
          if (res.status === 200) {
            this.cropperShow = false
            //把IM头像存在本地，防止刷新set页面，头像丢失
            storage.set('avatarIM', res.data.content.avatar)
            this.avatarIM = res.data.content.avatar
            this.$store.commit('updateCurrentUserAvatar', this.avatarIM)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 编辑更换邮箱
    changeEmail() {
      this.changeEmailVisible = true
    },
    // 编辑密码
    changePassword() {
      this.changePasswordVisible = true
    },
    handlepassword(rule, value, callback) {
      const regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z~!@#$%^&*()_+`\-={}:";'<>?,./ ]{8,20}$/
      if (!regex.test(value)) {
        callback(new Error('密码为8-20位，必须包含字母和数字，支持常用符号'))
      } else {
        callback()
      }
    },
    handlePasswordDetect(rule, value, callback) {
      const originalPassword = this.passwordForm.getFieldValue('originalPassword')
      const regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z~!@#$%^&*()_+`\-={}:";'<>?,./ ]{8,20}$/
      if (!regex.test(value)) {
        callback(new Error('密码为8-20位，必须包含字母和数字，支持常用符号'))
      } else if (value === undefined) {
        callback(new Error('请输入密码'))
      } else if (value && originalPassword && value === originalPassword) {
        callback(new Error('新密码不能与原密码一致，请重新输入'))
      }
      callback()
    },
    // 发送验证码
    getCaptcha(e) {
      e.preventDefault()
      const {
        emailForm: { validateFields },
        state,
        $message
        // $notification
      } = this
      const _this = this
      validateFields(['newEmail'], (err, values) => {
        if (!err) {
          state.smsSendBtn = true
          this.interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(_this.interval)
            }
          }, 1000)

          const hide = $message.loading('验证码发送中..', 0)

          getSmsCaptcha({ email: values.newEmail })
            .then(res => {
              setTimeout(hide, 2500)
            })
            .catch(err => {
              setTimeout(hide, 1)
              clearInterval(_this.interval)
              state.time = 60
              state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    // 修改邮箱提交数据
    handlechangeEmail() {
      const {
        emailForm: { validateFields }
      } = this
      const _this = this
      validateFields({ force: true }, (err, values) => {
        if (!err) {
          _this.loginBtn = true
          const emailForm = { ...values }
          emailForm.originalEmail = this.teacherBasicData.email
          resetEmail(emailForm)
            .then(res => {
              if (res.status === 200) {
                _this.$message.success('更换邮箱成功')
                _this.initTeacherInfo()
                _this.handleCancel()
              }
            })
            .catch(err => {
              _this.failedfuc(err.response)
            })
            .finally(() => {
              _this.loginBtn = false
            })
        }
      })
    },
    // 修改密码提交数据
    handlechangePassword() {
      const _this = this
      const {
        passwordForm: { validateFields },
        Login
      } = this
      validateFields({ force: true }, (err, values) => {
        if (!err) {
          _this.loginBtn = true
          const passwordForm = { ...values }
          setResetPassword(passwordForm)
            .then(res => {
              console.log(res)
              if (res.status === 200) {
                const loginParams = {}
                loginParams.email = _this.email
                loginParams.password = passwordForm.newPassword
                loginParams.rememberMe = storage.get(REMEMBERME)
                Login(loginParams).then(() => {
                  _this.$message.success('更换密码成功')
                  _this.initTeacherInfo()
                  _this.handleCancel()
                })
              }
            })
            .catch(err => {
              _this.failedfuc(err.response)
            })
            .finally(() => {
              _this.loginBtn = false
            })
        }
      })
    },
    // 取消弹窗
    handleCancel() {
      this.cropperShow = false
      this.changeEmailVisible = false
      this.changePasswordVisible = false
      this.loginBtn = false
      this.emailForm.resetFields()
      this.passwordForm.resetFields()
    },
    failedfuc(err) {
      console.log(err)
      const _this = this
      if (err.data.errors && err.data.errors.email === 'validation.serviceCenter.email.exist') {
        this.$confirm({
          title: '提示',
          content: '该邮箱已注册',
          okText: '忘记密码',
          cancelText: '取消',
          centered: true,
          onOk() {
            _this.$router.push({ name: 'register', query: { type: 'forgotPassword' } })
          },
          onCancel() {}
        })
      } else if (err.data.errors && err.data.errors.teacher === 'validation.serviceCenter.teacher.notFound') {
        this.$success({
          title: '提示',
          content: '未检测到邮箱录入信息，请联系管理员',
          okText: '好的',
          centered: true,
          onOk() {},
          onCancel() {}
        })
      } else if (err.data.message && err.data.message === 'Invalid target to retrieve user account!') {
        this.$confirm({
          title: '提示',
          content: '该邮箱还未注册',
          okText: '立即注册',
          cancelText: '取消',
          centered: true,
          onOk() {
            _this.$router.push({ path: 'register' })
          },
          onCancel() {}
        })
      } else if (err.data.errors.originalPassword === 'Incorrect password') {
        this.$store.commit('showMessage', {
          type: 'error',
          message: '原密码不正确'
        })
      }
    }
  },
  mounted() {
    this.avatarIM = storage.get('avatarIM')
    this.subject=storage.get('userInfo').subject
  }
}
</script>
<style lang="less" scoped>
.classPopover {
  .ant-popover-inner-content {
    width: 150px;
    padding: 0 16px;
  }
  .feedback {
    font-size: 14px;
    color: #333;
   
    text-align: center;
    &:hover {
      cursor: pointer;
      color: #009cff;
    }
  }
}
.set-main {
  height: calc(100vh - 100px);
  background-color: #fff;
  border-radius: 30px;
  overflow-y: auto;

  .set-contant {
    background-color: #fff;
    // max-width: 1200px;
    margin: auto;
    padding: 50px 0 50px 13%;
    .set-info {
      padding: 20px 0 40px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      width: 80%;
      justify-content: flex-start;
      .avatar {
        width: 74px;
        height: 74px;
        border-radius: 50px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .basic-name {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        vertical-align: bottom;
        padding-top: 10px;
      }
      .gender {
        width: 20px;
        height: 20px;
        margin: 0 12px;
        vertical-align: middle;
      }
      .teacher-address {
        margin-top: 19px;
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          padding: 2px 5px;
          background: #f7f7f7;
          border-radius: 6px;
          margin-right: 12px;
        }
      }
      .set-acc {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-top: 8px;
      }
    }
    .set-avator {
      padding-bottom: 40px;
      .set-title {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 24px;
        padding: 30px 0;
        margin: 0;
      }
      .set-data {
        display: flex;
        justify-content: flex-start;
      }
      .userAvatar {
        height: 180px;
        margin-right: 40px;
        img {
          width: 180px;
          height: 180px;
          border-radius: 50%;
        }
        div {
          width: 180px;
          height: 180px;
          margin: auto;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .avatarBox {
        &::after {
          content: '';
          width: 180px;
          height: 180px;
          display: block;
          position: relative;
          top: -180px;
          left: 50%;
          margin-left: -90px;
          background: url(~@/assets/service_pic_pendant_big.png) no-repeat center;
        }
      }
      .uploadbtn {
        p {
          margin: 0;
        }
        .avator-text {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 20px;
          padding: 20px 0;
        }
      }
      .ant-upload-text {
        width: 140px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        border-radius: 10px;
        border: 1px solid #009cff;
        margin: 50px auto 20px;
        color: #009cff;
        font-size: 16px;
        font-weight: 600;
        &:hover {
          cursor: pointer;
        }
      }
      .uploadTips {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666;
        line-height: 22px;
        text-align: center;
        margin-bottom: 0;
        span {
          color: #ff5353;
        }
      }
    }
    .set-safety {
      width: 700px;
      margin-bottom: 20px;
      .password,
      .email {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        line-height: 18px;
        span {
          color: #999;
          margin-right: 60px;
        }
        i {
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
