<template>
  <a-modal
    title="离职"
    :visible="visible"
    :footer="null"
    width="730px"
    dialogClass="schedule-related"
    @cancel="onCancel(0)"
  >
    <div v-if="formData">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item :label="formData.fields[0].name">
          <a-select
            size="large"
            v-decorator="[formData.fields[0].id, { rules: [{ required: true, message: '请选择交接类型!' }] }]"
            placeholder="请选择交接类型"
            @change="changeQuestion"
          >
            <a-select-option
              v-for="(item, index) in taskOverTypeArray"
              :key="index"
              :value="item.name.substring(item.name.indexOf('##') + 2)"
            >
              {{ item.name.substring(0, item.name.indexOf('##')) }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="formData.fields[1].name">
          <a-input v-decorator="[formData.fields[1].id, {}]" placeholder="申请人" disabled />
        </a-form-item>
        <a-form-item :label="formData.fields[2].name" :wrapper-col="{ span: 18 }">
          <a-date-picker
            size="large"
            style="width: 100%"
            format="YYYY-MM-DD HH:mm"
            :disabled-date="disabledDate"
            :show-time="{ format: 'HH:mm' }"
            placeholder="请选择离职时间（北京时间）"
            v-decorator="[
              formData.fields[2].id,
              { rules: [{ type: 'object', required: true, message: '请选择交接时间（北京时间）' }] },
            ]"
            @change="onResignClass"
          />
        </a-form-item>
        <!-- <a-form-item :label="formData.fields[3].name">
          <a-input v-decorator="[formData.fields[3].id, {}]" placeholder="班级交接周期" disabled /> -->
        <!-- <a-select
              size="large"
              v-decorator="[formData.fields[2].id, { rules: [{ required: true, message: '请选择问题!' }] }]"
              placeholder="请选择班级交接周期"
            >
              <a-select-option value="紧急交接">紧急交接</a-select-option>
              <a-select-option value="常规交接">常规交接</a-select-option>
            </a-select> -->
        <!-- </a-form-item> -->
        <!-- <a-form-item :label="formData.fields[4].name">
          <a-select
            size="large"
            v-decorator="[formData.fields[4].id, { rules: [{ required: true, message: '请选择离职对外话术!' }] }]"
            placeholder="请选择离职对外话术"
          >
            <a-select-option value="学生升入更高级别（大级别），申请安排其他老师"
              >学生升入更高级别（大级别），申请安排其他老师</a-select-option
            >
            <a-select-option value="老师转岗">老师转岗</a-select-option>
            <a-select-option value="老师进修，暂停教学工作">老师进修，暂停教学工作</a-select-option>
            <a-select-option value="其他（请补充在下列真实原因中）">其他（请补充在下列真实原因中）</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item :label="formData.fields[5].name">
          <a-select
            size="large"
            v-decorator="[formData.fields[5].id, { rules: [{ required: true, message: '请选择离职真实原因!' }] }]"
            placeholder="请选择离职真实原因"
            @change="checkRequiredOne"
          >
            <a-select-option v-for="(item, index) in formData.fields[5].options" :key="index" :value="item.name">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="其他真实原因" v-if="form.getFieldValue('description') === '其他'">
          <a-textarea
            v-decorator="[formData.fields[4].id, { rules: [{ required: true, message: '请输入离职真实原因!' }] }]"
            placeholder="请输入离职真实原因"
            :auto-size="{ minRows: 3 }"
            @change="checkRequired"
          />
        </a-form-item>
        <Handler v-if="assignees && isAssignees" :assignees="assignees" />
        <a-form-item :wrapper-col="{ span: 12, offset: 12 }" style="margin-bottom: 0">
          <a-button @click="onCancel(0)"> 取消 </a-button>
          <a-button type="primary" @click="showTips" :disabled="noAssigness" class="feedBackbtn"> 提交 </a-button>
        </a-form-item>
      </a-form>
      <a-modal
        :visible="resignVisible"
        :footer="null"
        dialogClass="submit-tips"
        width="560px"
        @cancel="onCanceltips"
        :maskClosable="false"
      >
        <div class="tips-main">
          <p class="tips-title">确认提交“离职申请” ？</p>
          <p class="tips-text">
            此申请通过后，班主任需前往“<span>我的任务-待办</span>”，完成系统生成的“<span>班主任离职班级交接学生</span>”任务
          </p>
          <p class="tips-err">注意：此申请一旦提交不可撤回</p>
        </div>
        <div class="btn">
          <a-button @click="onCanceltips"> 取消 </a-button>
          <a-button type="primary" @click="handleSubmit"> 提交 </a-button>
        </div>
      </a-modal>
    </div>
  </a-modal>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import Handler from '@/components/WKHandler/index.vue';
// import { imgpostUrl } from '@/api/register';
import { UUID, USERINFOSERVER, ROLES } from '@/store/mutation-types';
import { initFormResign, initOptions, initAssignees, createTakeOverForm } from '@/api/headTeacher';
// const { teacherName } = storage.get(TEACHERINFO);
// const LOAD_NUM = 20;
export default {
  components: { Handler },
  props: {
    reSubmitData: {
      type: Object,
      default: () => {},
    },
    classInfoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      uuid: storage.get(UUID),
      accountId: undefined,
      teacherName: storage.get(USERINFOSERVER).fullName,
      roles: storage.get(ROLES),
      visible: true,
      form: this.$form.createForm(this),
      formData: null,
      assignees: null,
      isAssignees: false,
      processName: '',
      options: null,
      noAssigness: false,
      classArray: [],
      pageNum: 1,
      changeCourseId: '',
      // studentArray: this.classInfoData.students || [],
      studentArray: [],
      lessonsInvolvedData: [],
      expandClassNum: '',
      questionVale: 'resign-take-over-classAdmin',
      upGradeSection: [],
      taskOverTypeArray: [{ name: '班主任离职申请##resign-take-over-classAdmin' }],
      courseStructure: [],
      renderedOptions: [],
      dataList: null,
      num: 1,
      resignVisible: null,
    };
  },
  mounted() {
    // if (this.classInfoData.classType === 'PRIVATE' && this.classInfoData.courseType !== 'STANDARD') {
    //   this.taskOverTypeArray = this.taskOverTypeArray.filter((item) => item.name.includes('adjust-time-take-over'));
    //   this.questionVale = 'adjust-time-take-over';
    // } else if (this.classInfoData.classType !== 'PRIVATE' && this.classInfoData.courseType === 'STANDARD') {
    //   this.taskOverTypeArray = this.taskOverTypeArray.filter((item) => item.name.includes('upgrade-take-over'));
    //   this.questionVale = 'upgrade-take-over';
    // }
    console.log(this.questionVale);
    this.initForm(`${this.questionVale}-form`); // adjust-time-take-over-form  upgrade-take-over-form
  },
  methods: {
    moment,
    // onOutProcedure() {
    //   this.checkRequired();
    //   this.form.getFieldDecorator('outProcedure', { initialValue: '' });
    // },
    // debounce(fn, wait) {
    //   let timer = null;
    //   return function () {
    //     if (timer !== null) {
    //       clearTimeout(timer);
    //     }
    //     timer = setTimeout(fn, wait);
    //   };
    // },
    // 滚动时触发（防止抖动）
    // handlePopupScroll() {
    //   this.loadMoreData();
    // },
    // 加载更多数据到select框
    // loadMoreData() {
    //   const renderedLen = this.renderedOptions.length; // 已渲染的下拉列表长度
    //   const totalLen = this.dataList.totalCount; // 当前数据源的长度
    //   // let addList = [];
    //   if (this.num >= this.dataList.totalPages) return;
    //   if (renderedLen < totalLen) {
    //     if (renderedLen + LOAD_NUM <= totalLen) {
    //       this.num += 1;
    //       this.upGradeSectionData(this.num);
    //       // addList = dataList.slice(renderedLen, renderedLen + LOAD_NUM);
    //     } else {
    //       // addList = dataList.slice(renderedLen, renderedLen + (totalLen % LOAD_NUM));
    //     }
    //     // this.renderedOptions = this.renderedOptions.concat(addList);
    //   }
    // },
    onCanceltips() {
      this.resignVisible = null;
    },
    showTips() {
      this.resignVisible = true;
    },
    // 交接班升级后课节进度列表
    upGradeSectionData(num) {
      if (!this.classInfoData.currentSectionData) return;
      const data = {
        courseId: this.classInfoData.courseId,
        pageNum: num,
        courseStructure: {
          level1: this.classInfoData.currentSectionData.level1,
          subjectType: this.classInfoData.currentSectionData.subjectType,
        },
      };
      this.$post(`/api/teacher/work_flow/course/section/search`, data).then((res) => {
        this.dataList = res.data.content;
        this.renderedOptions = this.renderedOptions.concat(res.data.content.content);
      });
    },
    // 离职周期
    onResignClass(value) {
      const times = moment(value).format('X') - moment().format('X');
      if (times > 0 && times <= 10 * 24 * 60 * 60) {
        this.form.getFieldDecorator('takeOverPeriod', { initialValue: '紧急交接' });
      } else {
        this.form.getFieldDecorator('takeOverPeriod', { initialValue: '常规交接' });
      }
    },
    // 检查交接合规
    // onHandoverClass(value) {
    //   const times = moment(value).format('X') - moment(this.classInfoData.beginDateTime).format('X');
    //   const diffTime = moment(value).format('X') - moment().format('X');
    //   if (times < 90 * 24 * 60 * 60) {
    //     this.form.getFieldDecorator('reasonable', { initialValue: '否，在班时间小于90天交班不合规' });
    //   } else {
    //     this.form.getFieldDecorator('reasonable', { initialValue: '是' });
    //   }
    //   if (diffTime > 0 && diffTime <= 10 * 24 * 60 * 60) {
    //     this.form.getFieldDecorator('takeOverPeriod', { initialValue: '紧急交接' });
    //   } else if (diffTime > 10 * 24 * 60 * 60) {
    //     this.form.getFieldDecorator('takeOverPeriod', { initialValue: '常规交接' });
    //   }
    // },
    // // 调整时间班级信息
    // checkClassInfo(value) {
    //   this.lessonsInvolvedData = [];
    //   // const startTime = moment(leaveDate[0]).format('YYYY-MM-DD HH:mm:ss');
    //   const endTime = moment(value).format('YYYY-MM-DD HH:mm:ss');
    //   const data = {
    //     startDateTimeFrom: this.classInfoData.beginDateTime,
    //     startDateTimeTo: endTime,
    //     allData: true,
    //     pageNum: 1,
    //     formClassId: this.classInfoData.uuid,
    //   };
    //   this.$post('/api/teacher/work_flow/associated/form_class', data).then((res) => {
    //     if (res.data.content.length === 0) {
    //       this.initClassInfo([this.classInfoData]);
    //     } else {
    //       this.classInfo = res.data.content;
    //       this.classInfo.forEach((item) => {
    //         this.studentArray = this.studentArray.concat(...item.students);
    //       });
    //       this.initClassInfo(this.classInfo);
    //     }
    //   });
    // },
    // // 处理涉及班级信息
    // initClassInfo(classInfo) {
    //   classInfo.forEach((item) => {
    //     const o = {};
    //     o.className = item.className;
    //     o.classType = item.classType === 'PRIVATE' ? '私教课' : '班课';
    //     o.courseType = this.initOptionsType(item.courseType, this.options.courseType);
    //     if (item.courseSchedules) {
    //       o.lessonsInvolved = item.courseSchedules.length;
    //       const scheduleArray = [];
    //       item.courseSchedules.forEach((k) => {
    //         const level1 = k.courseSectionRespDTO.data.level1 ? `${k.courseSectionRespDTO.data.level1}-` : '';
    //         const level2 = k.courseSectionRespDTO.data.level2 ? `${k.courseSectionRespDTO.data.level2}-` : '';
    //         const level3 = k.courseSectionRespDTO.data.level3 ? `${k.courseSectionRespDTO.data.level3}` : '';
    //         const currentProgress = level1 + level2 + level3;
    //         const courseLevel3 = k.courseSectionRespDTO.data.level3 ? `第${k.courseSectionRespDTO.data.level3}课-` : '';
    //         const courseSectionName = `${courseLevel3}${k.scheduleName}`;
    //         const scheduleName = `${currentProgress} ${this.moment(k.startChinaDateTime).format(
    //           'YY/MM/DD',
    //         )} ${this.moment(k.startChinaDateTime).format('dddd')} ${k.startChinaDateTime.substring(
    //           10,
    //         )}${k.endChinaDateTime.substring(10)}`;
    //         scheduleArray.push({ courseSectionName, scheduleName });
    //       });
    //       o.currentProgress = `${item.currentSectionData.level1 ? `${item.currentSectionData.level1}-` : ''}${
    //         item.currentSectionData.level2 ? `${item.currentSectionData.level2}-` : ''
    //       }${item.currentSectionData.level3 ? `${item.currentSectionData.level3}-` : ''}`;
    //       o.scheduleArray = scheduleArray;
    //     }
    //     const studentsArray = [];
    //     item.students.forEach((k) => {
    //       studentsArray.push({ name: k.name, code: k.code, id: k.id });
    //     });
    //     o.studentsArray = studentsArray;
    //     this.lessonsInvolvedData.push(o);
    //   });
    // },
    initQuestion() {
      // this.form.getFieldDecorator('question', { initialValue: this.questionVale });
      // switch (this.questionVale) {
      //   case 'upgrade-take-over':
      //     this.form.getFieldDecorator('className', { initialValue: this.classInfoData.className });
      //     this.form.getFieldDecorator('classType', {
      //       initialValue: this.classInfoData.classType === 'PRIVATE' ? '私教课' : '班课',
      //     });
      //     this.form.getFieldDecorator('firstCourseDate', { initialValue: this.classInfoData.beginDateTime });
      //     if (this.classInfoData.currentSectionData) {
      //       this.form.getFieldDecorator('studyProgress', {
      //         initialValue: `${this.classInfoData.currentSectionData.level1}-${this.classInfoData.currentSectionData.level2}-${this.classInfoData.currentSectionData.level3}`,
      //       });
      //     } else {
      //       this.form.getFieldDecorator('studyProgress', { initialValue: '暂无数据' });
      //     }
      //     this.upGradeSectionData(this.num);
      //     break;
      //   case 'adjust-time-take-over':
      //     this.form.getFieldDecorator('className', { initialValue: this.classInfoData.className });
      //     this.form.getFieldDecorator('classType', {
      //       initialValue: this.classInfoData.classType === 'PRIVATE' ? '私教课' : '班课',
      //     });
      //     this.form.getFieldDecorator('firstCourseDate', { initialValue: this.classInfoData.beginDateTime });
      //     this.form.getFieldDecorator('nextCourseDate', {
      //       initialValue: this.moment(this.classInfoData.currentScheduleStartDateTime),
      //     });
      //     if (this.classInfoData.currentSectionData) {
      //       this.form.getFieldDecorator('studyProgress', {
      //         initialValue: `${this.classInfoData.currentSectionData.level1}-${this.classInfoData.currentSectionData.level2}-${this.classInfoData.currentSectionData.level3}`,
      //       });
      //     } else {
      //       this.form.getFieldDecorator('studyProgress', { initialValue: '暂无数据' });
      //     }
      //     this.checkClassInfo(this.classInfoData.currentScheduleStartDateTime);
      //     break;
      //   default:
      //     break;
      // }
      // this.initAssigness();
    },
    // changeClassType() {
    //   this.form.resetFields(['leaveDate']);
    //   this.assignees = null;
    //   this.isAssignees = false;
    // },
    changeQuestion(value) {
      this.questionVale = value;
      this.initForm(`${value}-form`);
      this.assignees = null;
      this.isAssignees = false;
    },
    // expandHalder(value) {
    //   this.expandClassNum = this.expandClassNum ? '' : value;
    // },
    disabledDate(current) {
      return current && current < moment().subtract(1, 'days');
    },
    initAssigness(value) {
      this.processName = value;
      const data = {
        accountIds: [],
        businessKey: 'resign-take-over-classAdmin-form',
        processKey: 'resign-take-over-classAdmin',
      };
      const accountArray = this.studentArray;
      if (this.questionVale !== 'resign-take-over') {
        accountArray.forEach((item) => {
          if (item.classAdmin && item.classAdmin.CHINESE) {
            data.accountIds.push(item.classAdmin.CHINESE.accountId);
          }
        });
      }
      this.assignees = null;

      initAssignees(data)
        .then((res) => {
          this.assignees = res.data.content;
          this.noAssigness = false;
        })
        .catch(() => {
          this.noAssigness = true;
        });
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    initForm(value) {
      this.formData = null;
      let params = {
        type: value,
      };
      initFormResign(params).then((res) => {
        this.formData = res.data.content;
        //获取处理人
        this.initAssigness();
        this.form.getFieldDecorator('question', { initialValue: this.questionVale });
        this.form.getFieldDecorator('applyUser', { initialValue: this.teacherName });
      });
      //拿去枚举的类型
      initOptions().then((res) => {
        this.options = res.data.content;
      });
    },
    initSubmit(data) {
      const assigneesName = [];
      const taskAssigneesArray = [];
      const studentsArray = [];
      const questionOptions = this.taskOverTypeArray;
      let questionName = '';
      questionOptions.forEach((item) => {
        if (item.name.includes(this.questionVale)) {
          questionName = item.name.substring(0, item.name.indexOf('##'));
        }
      });
      // this.classInfoData.students.forEach((k) => {
      //   studentsArray.push({ name: k.name, id: k.id, code: k.code });
      // });
      // // 升级交接班节点Sid
      // const upgradeSid = [
      //   'sid-35A0A91F-6A52-4BDA-B958-450CAFDDEE69',
      //   'sid-321221BE-14B7-4D92-838B-9256759AC9C1',
      //   'sid-9DA2C130-2CB9-40B8-9D43-D092CFE9BBB0',
      // ];
      // // 调整时间Sid
      // const adjustSid = [
      //   'sid-013FBEAF-0BE4-429A-A986-E19F011B1AC1',
      //   'sid-56BD6F95-7700-402D-93D4-BE4AECEFA5B7',
      //   'sid-82589C1E-7330-4E1C-AEED-F1829E832C45',
      // ];
      // 提交人角色
      const roleStr = [];
      this.roles.forEach((i) => {
        roleStr.push(this.initOptionsType(i, this.options.userRole));
      });
      // 离职时间Sid
      const resignSid = ['userTask1', 'userTask2'];
      let sidArray = [];
      let formData = {};
      let dueDateTime = '';
      // if (data.question === 'upgrade-take-over') {
      //   sidArray = upgradeSid;
      //   dueDateTime = `${data.takeOverDate.substring(0, 10)}T${data.takeOverDate.substring(11)}`;
      //   formData = {
      //     question: questionName,
      //     className: data.className,
      //     classType: data.classType,
      //     takeOverDate: data.takeOverDate,
      //     firstCourseDate: data.firstCourseDate,
      //     reasonable: data.reasonable.substring(0, 1),
      //     takeOverPeriod: data.takeOverPeriod,
      //     studyProgress: data.studyProgress,
      //     upgradeProgress: data.upgradeProgress,
      //     upgradeFirstCourseDate: data.takeOverDate,
      //     description: data.description,
      //     classState: data.classState,
      //     outProcedure: data.outProcedure,
      //     reason: data.reason,
      //     assignees: assigneesName.join(','),
      //     carbonCopyUsers: '',
      //     students: studentsArray,
      //   };
      //   this.initClassInfo([this.classInfoData]);
      // } else if (data.question === 'adjust-time-take-over') {
      //   sidArray = adjustSid;
      //   dueDateTime = `${data.nextCourseDate.substring(0, 10)}T${data.nextCourseDate.substring(11)}`;
      //   formData = {
      //     question: questionName,
      //     selectDate: data.selectDate,
      //     outProcedure: data.outProcedure,
      //     reason: data.reason,
      //     firstCourseDate: data.firstCourseDate,
      //     className: data.className,
      //     students: studentsArray,
      //     studyProgress: data.studyProgress,
      //     nextCourseDate: data.nextCourseDate,
      //     description: data.description,
      //     classState: data.classState,
      //     assignees: assigneesName.join(','),
      //     carbonCopyUsers: '',
      //   };
      // } else {
      sidArray = resignSid;
      let currentTime = moment().add(2, 'days').format('YYYY-MM-DD HH:mm:ss');
      dueDateTime = `${currentTime.substring(0, 10)}T${currentTime.substring(11)}`;
      formData = {
        applyUser: data.applyUser,
        question: questionName,
        takeOverDate: data.takeOverDate,
        takeOverPeriod: data.takeOverPeriod,
        outProcedure: data.outProcedure,
        description: data.description,
        assignees: assigneesName.join(','),
        carbonCopyUsers: '',
      };
      // this.initClassInfo([this.classInfoData]);
      // }
      // 处理人节点
      if (this.assignees) {
        this.assignees.dealAccounts.forEach((item, index) => {
          item.accounts.forEach((k) => {
            assigneesName.push(k.fullName);
          });
          const assigneeIds = [];
          const taskAssigneeName = [];
          item.accounts.forEach((k) => {
            taskAssigneeName.push({
              assigneeName: k.fullName,
              assignee: k.uuid,
            });
            assigneeIds.push(k.uuid);
          });
          taskAssigneesArray.push({
            sid: sidArray[index],
            taskName: questionName,
            taskCategory: '班主任离职申请',
            assignees: taskAssigneeName,
            type: item.assigneeType,
          });
        });
        formData.assignees = assigneesName.join(',');
      }
      let changeCourseId = '';
      if (this.reSubmitData) {
        changeCourseId = this.changeCourseId || this.reSubmitData?.processVariables.courseScheduleId;
      } else {
        changeCourseId = this.$route.query.uuid;
      }
      // 表单启动参数
      const datas = {
        businessKey: `${data.question}-form`,
        // 表单数据
        formData,
        name: '班主任离职申请',
        processKey: data.question,
        // 发起人ID
        startUserId: this.accountId,
        // 第二节点审批人
        variables: {
          startUserName: this.teacherName,
          startUserRole: 'CLASS_ADMIN',
          courseScheduleId: changeCourseId,
          dueDate: dueDateTime,
          // taskType: 'STANDARD',
          processType: data.question,
          students: studentsArray,
          // taskType: this.classInfoData.courseType,
          // classType: this.classInfoData.classType,
          relatedClass: this.lessonsInvolvedData,
          applyUserRoles: roleStr.join(','),
          applyUserUuid: this.uuid,
        },
        taskAssignees: taskAssigneesArray,
      };
      createTakeOverForm(datas)
        .then((res) => {
          if (res.status === 200) {
            this.onCancel('submit');
          }
        })
        .catch(() => {
          this.onCancel(0);
        });
    },
    checkClassRequired() {
      this.$nextTick(() => {});
    },
    checkRequiredOne() {
      // this.form.getFieldDecorator('outProcedure', { initialValue: undefined });
      // console.log(this.form.getFieldDecorator('outProcedure', {}));
      this.checkRequired();
    },
    checkRequired() {
      this.$nextTick(() => {
        const getFieldsValue = this.form.getFieldsValue();
        this.isAssignees = Object.entries(getFieldsValue).every((item) => item[1] !== undefined && item[1]);
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const formData = values;
          const takeOverDate = this.moment(formData.takeOverDate).format('YYYY-MM-DD HH:mm:ss');
          formData.takeOverDate = takeOverDate;
          const nextCourseDate = this.moment(formData.nextCourseDate).format('YYYY-MM-DD HH:mm:ss');
          formData.nextCourseDate = nextCourseDate;
          this.initSubmit(formData);
        }
      });
    },
    onCancel(value) {
      this.$emit('cancal', value);
    },
  },
};
</script>
<style lang="less">
.schedule-related {
  .associatedClass {
    padding: 10px 0 0 10px;
    border-radius: 8px;
    border: 1px solid #eee;
    margin-top: 10px;
    .associatedClassInfo {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      .class-name {
        display: flex;
        justify-content: flex-start;
        width: 92%;
      }
      p {
        max-width: 80%;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-bottom: 0;
      }
      .title,
      .classInfoExpand {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }
      .studnets {
        display: flex;
        flex-wrap: wrap;
        span {
          margin-right: 5px;
        }
      }
    }
    .involved-lesson {
      flex-wrap: wrap;
      width: 100%;
      p {
        max-width: 100%;
        font-size: 14px;
        margin: 6px 0 0;
      }
    }
  }
  .ant-modal-content {
    border-radius: 30px;
  }
  .ant-modal-header {
    border-radius: 30px 30px 0 0;
  }
  .ant-btn {
    width: 150px;
    height: 46px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .feedBackbtn[disabled] {
    background-color: #f5f5f5;
  }
}
.submit-tips {
  .ant-modal-content {
    padding: 20px 20px 0;
    border-radius: 30px;
    .btn {
      display: flex;
      justify-content: space-between;
      .ant-btn {
        width: 200px;
        height: 46px;
        margin: 20px 0 40px;
        border-radius: 10px;
        font-size: 16px;
      }
    }
  }
  .tips-main {
    text-align: center;
    .tips-title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      margin-bottom: 20px;
    }
    .tips-text {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: left;
      span {
        font-weight: 600;
      }
    }
    .tips-err {
      font-size: 18px;
      font-weight: 400;
      color: #ff5353;
      line-height: 25px;
      text-align: left;
    }
  }
}
</style>
