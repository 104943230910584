<template>
  <div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" :tab="`处理人(${assigneesNum.dealAccounts})`" v-if="assigneesNum.dealAccounts !== 0">
        <div v-for="(item, index) in assigneesList.dealAccounts" :key="index">
          <OneHandler
            v-if="item.accounts.length === 1"
            :assigneesItem="item"
            :lineBol="index === assigneesList.dealAccounts.length - 1"
          />
          <MoreHandler v-else :assigneesItem="item" :lineBol="index === assigneesList.dealAccounts.length - 1" />
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="`抄送人(${assigneesNum.copyAccounts})`" v-if="assigneesNum.copyAccounts !== 0">
        <div v-for="(item, index) in [assigneesList.copyAccounts]" :key="index">
          <OneHandler
            v-if="item.accounts.length === 1"
            :assigneesItem="item"
            type="copy"
            :lineBol="index === assigneesList.dealAccounts.length - 1"
          />
          <MoreHandler
            v-else
            :assigneesItem="item"
            type="copy"
            :lineBol="index === assigneesList.dealAccounts.length - 1"
          />
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import OneHandler from './OneHandler.vue';
import MoreHandler from './MoreHandler.vue';

export default {
  components: { OneHandler, MoreHandler },
  props: {
    assignees: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    assigneesNum() {
      let dealAccounts = 0;
      const copyAccounts = this.assigneesList.copyAccounts.accounts.length;
      this.assigneesList.dealAccounts.forEach((item) => {
        dealAccounts += item.accounts.length;
      });
      return { dealAccounts, copyAccounts };
    },
  },
  data() {
    return {
      assigneesList: JSON.parse(JSON.stringify(this.assignees)),
    };
  },
  methods: {},
};
</script>
