<template>
  <div class="handler-more">
    <div class="handler-main">
      <div>
        <img src="@/assets/icons/home_icon_multi.svg" alt="" class="handler-img" />
        <div class="line" v-if="!lineBol"></div>
      </div>
      <div class="handler-info">
        <div class="handler-teacher">
          <div class="teacher-list">
            <p>多人处理</p>
            <span class="transfer" v-if="assigneesItem.label">({{ assigneesItem.label }})</span>
          </div>
          <p></p>
        </div>
        <!-- <div style="margin: 0 0 10px 0" v-if="type !== 'copy'">
          <span class="handler-status pending-status">等待上一步完成</span>
        </div> -->
        <div class="handler-name">
          <div class="teacher-info" v-for="item in assigneesList" :key="item.uuid">
            <img :src="defaultImg" alt="" class="teacher-img" />
            <span class="teacher-name">{{ item.fullName }}</span>
            <span>{{ item.roleInfos }}{{ item.departmentsName ? `(${item.departmentsName})` : '' }}</span>
          </div>
          <div class="more" @click="moreHandler" v-if="assigneesItem.accounts.length > 3">
            <span v-if="!isMore">+ {{ assigneesItem.accounts.length - 3 }}</span>
            <a-icon :type="!isMore ? 'caret-down' : 'caret-up'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import defaultImg from '@/assets/service_pic_head.png';

export default {
  props: {
    assigneesItem: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    lineBol: {
      type: Boolean,
    },
  },
  data() {
    return {
      defaultImg,
      assigneesList: JSON.parse(JSON.stringify(this.assigneesItem.accounts)).slice(0, 3),
      isMore: false,
    };
  },
  methods: {
    moreHandler() {
      if (!this.isMore) {
        this.assigneesList = JSON.parse(JSON.stringify(this.assigneesItem.accounts));
        this.isMore = true;
      } else {
        this.assigneesList = JSON.parse(JSON.stringify(this.assigneesItem.accounts)).slice(0, 3);
        this.isMore = false;
      }
    },
  },
};
</script>
<style lang="less">
.handler-more {
  p {
    margin: 0;
  }
  .handler-main {
    display: flex;
    justify-content: flex-start;
  }
  .handler-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .line {
    width: 0;
    height: 100%;
    border-left: 1px solid #eee;
    margin: auto;
  }
  .handlering {
    border: 1px solid #009cff;
  }
  .handler-info {
    width: 93%;
    margin-left: 10px;
    .handler-teacher {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      font-size: 16px;
      color: #999;
      margin-bottom: 10px;
      .teacher-list {
        width: 80%;
        display: flex;
        justify-content: flex-start;
        // align-items: center;
        .transfer {
          margin: 0 10px;
          color: #999 !important;
        }
        p {
          display: inline-block;
          max-width: 43%;
          line-height: 22px;
          color: #333;
          span {
            color: #009cff;
          }
        }
      }
    }
  }
  .handler-name {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    color: #333;
    font-size: 14px;
    .teacher-info {
      width: 30%;
      margin-bottom: 10px;
      .teacher-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .teacher-name {
        color: #009cff;
        margin: 0 5px;
      }
    }
    .more {
      width: 100%;
    }
  }
  .handler-status {
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 6px;
    color: #ffbf00;
  }
}
</style>
