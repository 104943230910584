<template>
  <div class="handler-one">
    <div class="handler-main" v-for="item in assigneesItem.accounts" :key="item.uuid">
      <div>
        <img :src="defaultImg" alt="" class="handler-img" />
        <div class="line" v-if="!lineBol"></div>
      </div>
      <div class="handler-info">
        <div class="handler-teacher">
          <div class="teacher-list">
            <p class="teacher-name">
              <span>{{ item.fullName }}</span> {{ item.roleInfos
              }}{{ item.departmentsName ? `(${item.departmentsName})` : '' }}
            </p>
          </div>
          <!-- <p v-if="source || type === 'copy'">{{ item.whenModified.substring(5, 16) }}</p> -->
        </div>
        <!-- <div style="margin: 0 0 10px 0" v-if="type !== 'copy'">
          <span v-if="!source" class="handler-status pending-status">待处理</span>
          <span v-if="false" class="handler-status success-status">已完成</span>
          <span v-if="false" class="handler-status refuse-status">已拒绝</span>
        </div> -->
        <div style="margin: 0 0 10px 0" v-if="type !== 'copy'">
          <span v-if="!source" class="handler-status"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import defaultImg from '@/assets/service_pic_head.png';

export default {
  props: {
    assigneesItem: {
      type: Object,
      default: () => {},
    },
    source: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    lineBol: {
      type: Boolean,
    },
  },
  data() {
    return {
      defaultImg,
    };
  },
  methods: {},
};
</script>
<style lang="less">
.handler-one {
  p {
    margin: 0;
  }
  .handler-main {
    display: flex;
    justify-content: flex-start;
  }
  .handler-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .line {
    width: 0;
    height: 100%;
    border-left: 1px solid #eee;
    margin: auto;
  }
  .handlering {
    border: 1px solid #009cff;
  }
  .handler-info {
    width: 93%;
    margin-left: 10px;
    .handler-teacher {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      font-size: 16px;
      color: #999;
      margin-bottom: 10px;
      .teacher-list {
        width: 80%;
        display: flex;
        justify-content: flex-start;
        // align-items: center;
        .transfer {
          margin: 0 10px;
          color: #999 !important;
        }
        p {
          display: inline-block;
          max-width: 43%;
          line-height: 22px;
          color: #333;
          span {
            color: #009cff;
          }
        }
        .teacher-name {
          max-width:74%;
        }
      }
    }
    .handler-status {
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 6px;
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .handler-reply {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: 10px 0;
      padding: 10px;
      border-radius: 10px;
      background-color: #f7f7f7;
      p {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
      .reply-btn {
        color: #333;
        margin-top: 20px;
        span {
          color: #009cff;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
