<template>
  <a-modal :visible="cropperShow" width="440px" :maskClosable="false" :footer="null" @cancel="cancel">
    <h3 slot="title">上传头像</h3>
    <div class="vue-cropper-layer">
      <vueCropper
        ref="cropper"
        class="cropper"
        autoCrop
        centerBox
        fixed
        fixedBox
        :img="cropperImg"
        autoCropWidth="180px"
        autoCropHeight="180px"
        outputType="jpeg"
      ></vueCropper>
    </div>
    <div class="cropper-btn">
      <a-button type="primary" @click="confirm" :loading="loading">上传</a-button>
      <a-button @click="cancel" :disabled="loading">取消</a-button>
    </div>
  </a-modal>
</template>

<script>
import { VueCropper } from 'vue-cropper'
import { actionUrl, imgpostUrl } from '@/api/register'
import storage from 'store'
import sessionStorage from 'store/storages/sessionStorage'
import { REMEMBERME, ACCESS_TOKEN } from '@/store/mutation-types'
const session = storage.createStore(sessionStorage)
const _token = storage.get(ACCESS_TOKEN)
export default {
  name: 'dialogCropper',
  components: {
    VueCropper
  },
  props: {
    cropperShow: {
      default: false,
      type: Boolean
    },
    cropperImg: {
      default: '',
      type: String
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      option: {
        size: 0,
        outputType: '',
        centerBox: false
      },
      loading: false,
      updateImgData: {}
    }
  },
  methods: {
    confirm() {
      console.log(2)
      this.loading = true
      this.$refs.cropper.getCropData(data => {
        // 将剪裁后base64的图片转化为file格式
        const blob = this.convertBase64UrlToBlob(data)
        const file = this.blobToFile(blob, this.info.file.name)
        actionUrl({ type: _token }).then(res => {
          this.uploadToOss(file, res.data.content)
        })
      })
    },
    uploadToOss(info, fileData) {
      var formData = new FormData()
      const formArray = Object.entries(fileData.formFields)
      formArray.map(item => {
        formData.append(item[0], item[1])
      })
      formData.append('file', info)
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then(res => {
          this.updateImgData.fileStorageId = res.data.fileId
          this.updateImgData.avatar = res.data.url

          this.$emit('upload', this.updateImgData)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob(urlData) {
      const bytes = window.atob(urlData.split(',')[1]) // 去掉url的头，并转换为byte
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length)
      const ia = new Uint8Array(ab)
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/jpeg' })
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, { type: 'image/jpeg', lastModified: Date.now() })
    },
    // 取消
    cancel() {
      this.$parent.handleCancel()
    }
  }
}
</script>

<style lang="less" scoped>
.vue-cropper-layer {
  width: 400px;
  height: 400px;
  margin: 0 0 15px;
}
.cropper-btn {
  text-align: right;
  .ant-btn-primary {
    background-color: #009cff;
  }
}
</style>
